<template>
  <v-dialog v-model="changeStatus.dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">Cancel Application</v-card-title>
      <v-card-text
        >Are you sure you want to cancel this application
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="resetChangeStatus"
          >No, cancel</v-btn
        >
        <v-btn
          color="success"
          depressed
          :loading="changeStatus.loading"
          @click="changeStatusConfirmed"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      changeStatus: {
        form_status: null,
        errors: {},
        status: null,
        dialog: false,
        loading: false,
        form_id: null,
      },
      errors: {},
    };
  },

  methods: {
    changeStatusPrompt(application = null, status) {
      this.changeStatus.form_id = application.id;
      this.changeStatus.status = status;
      this.changeStatus.dialog = true;
    },
    resetChangeStatus() {
      this.changeStatus.errors = null;
      this.changeStatus.form_id = null;
      this.changeStatus.dialog = false;
      this.changeStatus.status = null;
      this.changeStatus.loading = false;
    },
    changeStatusConfirmed() {
      const appId = this.$route.params.id;
      const applicationId = this.changeStatus.form_id;

      this.changeStatus.loading = true;

      this.$store
        .dispatch("sbpm/applicationsStore/changeStatusApplication", {
          appId,
          applicationId,
          status: this.changeStatus.status,
        })
        .then(() => {
          // if (this.changeStatus.form_id != null) {
          //   location.reload();
          // } else {
          this.resetChangeStatus();
          //}
        })
        .catch((err) => {
          this.changeStatus.errors = err.response.data.errors;
          this.changeStatus.loading = false;
        });
    },
  },
};
</script>
