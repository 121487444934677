<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Consent</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="consent-form">
          <v-switch
            label="To include my contact details in the management systems used
                    by Struan Baptie Property Management. The management systems
                    are available to staff only, but limited information may
                    also be made available to contractors who undertake services
                    for us on behalf of clients and tenants.t"
            v-model="fields.consent_contact"
            :error="errors.hasOwnProperty('consent_contact')"
            :error-messages="errors['consent_contact']"
            inset
          ></v-switch>
          <v-switch
            label="To keep me informed of news, services and activities of
                    Struan Baptie Property Management."
            v-model="fields.consent_news"
            :error="errors.hasOwnProperty('consent_news')"
            :error-messages="errors['consent_news']"
            inset
          ></v-switch>
          <v-switch
            label="To contact me with regards to specific issues regarding a
                    property."
            v-model="fields.consent_property"
            :error="errors.hasOwnProperty('consent_property')"
            :error-messages="errors['consent_property']"
            inset
          ></v-switch>
          <v-switch
            label="To contact references provided by me."
            v-model="fields.consent_references"
            :error="errors.hasOwnProperty('consent_references')"
            :error-messages="errors['consent_references']"
            inset
          ></v-switch>
          <v-switch
            label="To share my personal information with professional companies
                    that undertake credit checks"
            v-model="fields.consent_credit_checks"
            :error="errors.hasOwnProperty('consent_credit_checks')"
            :error-messages="errors['consent_credit_checks']"
            inset
          ></v-switch>
          <p>
            I consent to Struan Baptie Property Management contacting me for the
            above purposes by:
          </p>

          <v-switch
            label="Post"
            v-model="fields.consent_post"
            :error="errors.hasOwnProperty('consent_post')"
            :error-messages="errors['consent_post']"
            inset
          ></v-switch>

          <v-switch
            label="Email"
            v-model="fields.consent_email"
            :error="errors.hasOwnProperty('consent_email')"
            :error-messages="errors['consent_email']"
            inset
          ></v-switch>

          <v-switch
            label="Phone"
            v-model="fields.consent_phone"
            :error="errors.hasOwnProperty('consent_phone')"
            :error-messages="errors['consent_phone']"
            inset
          ></v-switch>

          <v-switch
            label="SMS"
            v-model="fields.consent_sms"
            :error="errors.hasOwnProperty('consent_sms')"
            :error-messages="errors['consent_sms']"
            inset
          ></v-switch>
          <v-switch
            label="I certify that my answers are true and complete to the best
                    of my knowledge. I have read, understood and agree to all
                    the above terms &amp; conditions"
            v-model="fields.consent_post"
            :error="errors.hasOwnProperty('terms_agree')"
            :error-messages="errors['terms_agree']"
            inset
          ></v-switch>

          <v-text-field
            label="Type Name"
            v-model="fields.signature"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('signature')"
            :error-messages="errors['signature']"
          ></v-text-field>

          <v-text-field
            label="Date"
            v-model="fields.signed_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('signed_date')"
            :error-messages="errors['signed_date']"
          ></v-text-field>

          <!-- <v-menu
          v-model="startDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
          max-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Date"
              v-model="availableFromFormatted"
              prepend-inner-icon="mdi-calendar"
              outlined
              background-color="white"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fields.signed_date"
            @input="startDatePicker = false"
            first-day-of-week="1"
          ></v-date-picker>
        </v-menu>-->
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="consent-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      startDatePicker: false,
      fields: {
        consent_contact: false,
        consent_news: false,
        consent_property: false,
        consent_references: false,
        consent_credit_checks: false,
        consent_post: false,
        consent_email: false,
        consent_phone: false,
        consent_sms: false,
        terms_agree: false,
        signature: null,
        signed_date: null,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    availableFromFormatted() {
      return this.fields.signed_date;
    },
  },

  methods: {
    openForm: function (application = null) {
      if (application !== null) {
        this.application = application;
        this.fields.note_text = application.note_text;

        this.fields.consent_contact = application.consent_contact;
        this.fields.consent_news = application.consent_news;
        this.fields.consent_property = application.consent_property;
        this.fields.consent_references = application.consent_references;
        this.fields.consent_credit_checks = application.consent_credit_checks;
        this.fields.consent_post = application.consent_post;
        this.fields.consent_email = application.consent_email;
        this.fields.consent_phone = application.consent_phone;
        this.fields.consent_sms = application.consent_sms;
        this.fields.terms_agree = application.terms_agree;
        this.fields.signature = application.signature;
        // this.fields.signed_date = application.signed_date;

        this.fields.signed_date = moment(
          application.signed_date,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      this.fields.form_title = "consent";

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.applicationId = this.application.id;

      this.$store
        .dispatch("sbpm/applicationsStore/saveApplication", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields = {
        consent_contact: false,
        consent_news: false,
        consent_property: false,
        consent_references: false,
        consent_credit_checks: false,
        consent_post: false,
        consent_email: false,
        consent_phone: false,
        consent_sms: false,
        terms_agree: false,
        signature: null,
        signed_date: null,
      };
    },
  },
};
</script>
