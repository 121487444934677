<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit Basic Info</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="product-form">
          <v-text-field
            label="Title"
            v-model="fields.applicant_title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('applicant_title')"
            :error-messages="errors['applicant_title']"
          ></v-text-field>
          <v-text-field
            label="First Name"
            v-model="fields.applicant_first_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('applicant_first_name')"
            :error-messages="errors['applicant_first_name']"
          ></v-text-field>

          <v-text-field
            label="Middle Name(s)"
            v-model="fields.applicant_middle_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('applicant_middle_name')"
            :error-messages="errors['applicant_middle_name']"
          ></v-text-field>
          <v-text-field
            label="Surname"
            v-model="fields.applicant_last_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('applicant_last_name')"
            :error-messages="errors['applicant_last_name']"
          ></v-text-field>
          <v-text-field
            label="Address Line 1"
            v-model="fields.add1"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('add1')"
            :error-messages="errors['add1']"
          ></v-text-field>
          <v-text-field
            label="Address Line 2"
            v-model="fields.add2"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('add2')"
            :error-messages="errors['add2']"
          ></v-text-field>

          <v-text-field
            label="City"
            v-model="fields.town"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('town')"
            :error-messages="errors['town']"
          ></v-text-field>
          <v-text-field
            label="Postcode"
            v-model="fields.postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('postcode')"
            :error-messages="errors['postcode']"
          ></v-text-field>

          <v-menu
            v-model="datePicker"
            transition="scale-transition"
            offset-y
            min-width="290"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="DOB"
                v-model="dobDateFormatted"
                background-color="white"
                outlined
                :error="errors.hasOwnProperty('applicant_dob')"
                :error-messages="errors['applicant_dob']"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fields.applicant_dob"
              @input="datePicker = false"
              first-day-of-week="1"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            label="NI Number"
            v-model="fields.applicant_NI"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('applicant_NI')"
            :error-messages="errors['applicant_NI']"
          ></v-text-field>

          <v-switch
            label="Smoker"
            v-model="fields.smoker"
            :error="errors.hasOwnProperty('smoker')"
            :error-messages="errors['smoker']"
            inset
          ></v-switch>

          <v-switch
            label="Has Pets"
            v-model="fields.pets"
            :error="errors.hasOwnProperty('pets')"
            :error-messages="errors['pets']"
            inset
          ></v-switch>

          <v-text-field
            label="Dependents"
            v-model="fields.number_of_dependents"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('number_of_dependents')"
            :error-messages="errors['number_of_dependents']"
          ></v-text-field>

          <v-text-field
            label="Email"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>

          <v-text-field
            label="Mobile"
            v-model="fields.phone"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="product-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      datePicker: false,
      fields: {
        applicant_title: null,
        form_title: null,
        applicant_first_name: null,
        applicant_middle_name: null,
        applicant_last_name: null,
        applicant_dob: null,
        applicant_NI: null,
        add1: null,
        add2: null,
        town: null,
        postcode: null,
        phone: null,
        email: null,
        smoker: false,
        pets: false,
        number_of_dependents: null,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    dobDateFormatted() {
      return this.formatDate(this.fields.applicant_dob);
    },
  },

  methods: {
    openForm: function (application = null) {
      if (application !== null) {
        this.application = application;
        this.fields.applicant_title = application.applicant_title;
        this.fields.applicant_first_name = application.applicant_first_name;
        this.fields.applicant_middle_name = application.applicant_middle_name;
        this.fields.applicant_last_name = application.applicant_last_name;
        this.fields.applicant_dob = application.applicant_dob
          ? moment(application.applicant_dob, [
              "DD-MM-YYYY",
              "MM-DD-YYYY",
            ]).format("YYYY-MM-DD")
          : null;

        this.fields.applicant_NI = application.applicant_NI;
        this.fields.add1 = application.add1;
        this.fields.add2 = application.add2;
        this.fields.town = application.town;
        this.fields.postcode = application.postcode;
        this.fields.phone = application.phone;
        this.fields.email = application.email;
        this.fields.smoker = application.smoker == 1 ? true : false;
        this.fields.pets = application.pets == 1 ? true : false;
        this.fields.number_of_dependents = application.number_of_dependents;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      this.fields.form_title = "basic";

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.applicationId = this.application.id;

      this.$store
        .dispatch("sbpm/applicationsStore/saveApplication", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields = {
        applicant_title: null,
        applicant_first_name: null,
        applicant_middle_name: null,
        applicant_last_name: null,
        applicant_dob: null,
        applicant_NI: null,
        add1: null,
        add2: null,
        town: null,
        form_title: null,
        postcode: null,
        phone: null,
        email: null,
        smoker: false,
        pets: false,
        number_of_dependents: null,
      };
    },
  },
};
</script>
