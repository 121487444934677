<template>
  <v-dialog v-model="changeStatus.dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">Change Status</v-card-title>
      <v-card-text>
        <!-- Are you sure you wish to set this to
        <strong>{{ changeStatus.status }}</strong
        >?

        <div v-if="changeStatus.status == 'Landlord Advised of Entry Date'">
          {{ application.property.landlord_email }}
          {{ application.property.landlord_email_2 }}
        </div>
        <div v-else>
          {{ application.email }}
        </div>

        <div
          v-if="
            changeStatus.status == 'Landlord Advised of Entry Date' &&
            !application.property.landlord_email &&
            !application.property.landlord_email_2
          "
        >
          <br /><strong
            >WARNING: There is no landlord email associated with this property.
            You can set this status but no emails will be sent.</strong
          >
        </div> -->

        <v-form @submit.prevent="saveForm" method="post" id="status-form">
          <v-select
            label="Status"
            v-model="changeStatus.status"
            :items="statuses"
            item-value="value"
            item-text="label"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('status')"
            :error-messages="errors['status']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="resetChangeStatus"
          >No, cancel</v-btn
        >
        <v-btn
          color="success"
          depressed
          :loading="changeStatus.loading"
          @click="changeStatusConfirmed"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    application: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      changeStatus: {
        form_status: null,
        errors: {},
        status: null,
        dialog: false,
        loading: false,
        form_id: null,
      },
      statuses: [
        "Under Consideration",
        "Invited to Viewing",
        "Referencing in Progress",
        "With Landlord",
        "Accepted",
        "Landlord Advised of Entry Date",
        "Cancelled",
        "Rejected",
        "Submitted",
      ],
      errors: {},
    };
  },

  methods: {
    changeStatusPrompt(application = null, status) {
      this.changeStatus.form_id = application.id;
      this.changeStatus.status = status;
      this.changeStatus.dialog = true;
    },
    resetChangeStatus() {
      this.changeStatus.errors = null;
      this.changeStatus.form_id = null;
      this.changeStatus.dialog = false;
      this.changeStatus.status = null;
      this.changeStatus.loading = false;
    },
    changeStatusConfirmed() {
      const appId = this.$route.params.id;
      const applicationId = this.changeStatus.form_id;

      this.changeStatus.loading = true;

      this.$store
        .dispatch("sbpm/applicationsStore/changeStatusApplication", {
          appId,
          applicationId,
          status: this.changeStatus.status,
        })
        .then(() => {
          // if (this.changeStatus.form_id != null) {
          //   location.reload();
          // } else {
          this.resetChangeStatus();
          //}
        })
        .catch((err) => {
          this.changeStatus.errors = err.response.data.errors;
          this.changeStatus.loading = false;
        });
    },
  },
};
</script>
