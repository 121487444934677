<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit Guarantor Information</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="employment-form">
          <v-switch
            label="Guarantor Needed"
            v-model="fields.student_guarantor"
            inset
            :error="errors.hasOwnProperty('student_guarantor')"
            :error-messages="errors['student_guarantor']"
          ></v-switch>

          <v-text-field
            label="Name"
            v-model="fields.guarantor_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_name')"
            :error-messages="errors['guarantor_name']"
          ></v-text-field>

          <v-textarea
            label="Guarantor Address"
            v-model="fields.guarantor_address"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_address')"
            :error-messages="errors['guarantor_address']"
          ></v-textarea>

          <v-text-field
            label="Relationship"
            v-model="fields.guarantor_relationship"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_relationship')"
            :error-messages="errors['guarantor_relationship']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="employment-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        student_guarantor: false,
        guarantor_name: null,
        guarantor_address: null,
        guarantor_relationship: null,
      },

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  methods: {
    openForm: function (application = null) {
      if (application !== null) {
        this.application = application;
        this.fields.student_guarantor = application.student_guarantor;
        this.fields.guarantor_address = application.guarantor_address;
        this.fields.guarantor_relationship = application.guarantor_relationship;
        this.fields.guarantor_name = application.guarantor_name;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      this.fields.form_title = "guarantor";

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.applicationId = this.application.id;

      this.$store
        .dispatch("sbpm/applicationsStore/saveApplication", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
    },
  },
};
</script>
