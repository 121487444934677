<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit Employment Information</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="employment-form">
          <v-select
            label="Status"
            v-model="fields.employment_status"
            :items="statuses"
            item-value="value"
            item-text="label"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('employment_status')"
            :error-messages="errors['employment_status']"
          ></v-select>

          <v-text-field
            label="Occupation"
            v-model="fields.occupation"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('occupation')"
            :error-messages="errors['occupation']"
          ></v-text-field>

          <v-text-field
            label="Name"
            v-model="fields.employer_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('employer_name')"
            :error-messages="errors['employer_name']"
          ></v-text-field>

          <v-text-field
            label="Address Line 1"
            v-model="fields.employer_add_1"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('employer_add_1')"
            :error-messages="errors['employer_add_1']"
          ></v-text-field>

          <v-text-field
            label="Address Line 2"
            v-model="fields.employer_add_2"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('employer_add_2')"
            :error-messages="errors['employer_add_2']"
          ></v-text-field>

          <v-text-field
            label="Town"
            v-model="fields.employer_town"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('employer_town')"
            :error-messages="errors['employer_town']"
          ></v-text-field>

          <v-text-field
            label="Postcode"
            v-model="fields.employer_postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('employer_postcode')"
            :error-messages="errors['employer_postcode']"
          ></v-text-field>

          <v-text-field
            label="Monthly Income"
            v-model="fields.monthly_salary"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('monthly_salary')"
            :error-messages="errors['monthly_salary']"
          ></v-text-field>

          <v-textarea
            label="Notes"
            v-model="fields.other_means"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('other_means')"
            :error-messages="errors['other_means']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="employment-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        employment_status: null,
        occupation: null,
        employer_add_1: null,
        employer_name: null,
        employer_add_2: null,
        employer_town: null,
        employer_postcode: null,
        monthly_salary: null,
        other_means: null,
      },
      statuses: [
        "Employed",
        "Self Employed",
        "Unemployed",
        "Funding By Own Means",
        "Retired",
        "Student",
      ],
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  methods: {
    openForm: function (application = null) {
      if (application !== null) {
        this.application = application;
        this.fields.employment_status = application.employment_status;
        this.fields.occupation = application.occupation;
        this.fields.employer_name = application.employer_name;
        this.fields.employer_add_1 = application.employer_add_1;
        this.fields.employer_add_2 = application.employer_add_2;
        this.fields.employer_town = application.employer_town;
        this.fields.employer_postcode = application.employer_postcode;
        this.fields.monthly_salary = application.monthly_salary;
        this.fields.other_means = application.other_means;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      this.fields.form_title = "employment";

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.applicationId = this.application.id;

      this.$store
        .dispatch("sbpm/applicationsStore/saveApplication", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
    },
  },
};
</script>
